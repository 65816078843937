import getUserAddresses from "@faire/web-api/api/retailer/address/list/post";
import getUserAsync from "@faire/web-api/api/user/get";
import logout from "@faire/web-api/api/user/logout/post";
import type { CapabilityEnum } from "@faire/web-api/faire/session/CapabilityEnum";
import { IAddress } from "@faire/web-api/indigofair/data/IAddress";
import { IImage } from "@faire/web-api/indigofair/data/IImage";
import { IListRetailerAddressesRequest } from "@faire/web-api/indigofair/data/IListRetailerAddressesRequest";
import { IUser } from "@faire/web-api/indigofair/data/IUser";
import { Language } from "@faire/web-api/indigofair/languages/Language";
import { ILocaleKey } from "@faire/web-api/indigofair/locale/ILocaleKey";
import { IRetailerAccountUserManagement } from "@faire/web-api/indigofair/retailer/IRetailerAccountUserManagement";
import { IRetailerUserNotificationCount } from "@faire/web-api/indigofair/retailer/IRetailerUserNotificationCount";
import { RetailerUserNotificationGroup } from "@faire/web-api/indigofair/retailer/RetailerUserNotificationGroup";
import { IWebSocketMessage } from "@faire/web-api/indigofair/websockets/IWebSocketMessage";
import { DEFAULT_LOCALE_KEY } from "@faire/web/common/consts/DEFAULT_LOCALE";
import { SHOP_PREVIEW_OVERRIDE_KEY } from "@faire/web/common/consts/SHOP_PREVIEW_OVERRIDE_KEY";
import { localeStringToLocaleKey } from "@faire/web/common/consts/TWO_TO_THREE_LETTER_LANGUAGE_CODE";
import { getCookies } from "@faire/web/common/cookies";
import { CurrencyStore } from "@faire/web/common/currency/CurrencyStore";
import { getLocationOrThrow } from "@faire/web/common/globals/getLocation";
import { logError } from "@faire/web/common/logging";
import { makeObservable } from "@faire/web/common/makeObservable";
import { faireBaseUrl } from "@faire/web/common/routes/faireBaseUrl";
import { singletonGetter } from "@faire/web/common/singletons/getter";
import { isNotUndefined } from "@faire/web/common/typescriptUtils";
import { Message } from "@faire/web/messenger/MessageChannel/MessageChannel";
import { createStoreHook } from "@faire/web/ui/hooks/useStore";
import { action, computed, observable } from "mobx";

import {
  getBrowserDefaultLanguage,
  getBrowserDefaultLocaleKey,
} from "@faire/retailer/consts/LANGUAGE";
import { getLocaleKey } from "@faire/retailer/serialized-data/getLocaleKey";
import { getUser } from "@faire/retailer/serialized-data/getUser";
import { getRetailerUserNotificationCount } from "@faire/retailer/serialized-data/retailerUserNotificationCount";

/**
 * @deprecated prefer to use `useUserStore` or `withStores` instead
 */
export class UserStore {
  constructor() {
    makeObservable(this);
    this.fetchUserAddresses();
  }

  @observable
  userAddresses: IAddress[] = [];

  @observable
  private addressDeletableMap: Record<string, boolean> = {};

  @observable
  user?: IUser = getUser();

  private fetchUserAddresses = async () => {
    if (!this.user) {
      return;
    }
    try {
      const addresses = await getUserAddresses(
        IListRetailerAddressesRequest.build({})
      );

      if (!addresses) {
        return;
      }

      this.userAddresses = (addresses.addresses ?? [])
        .map((address) => address.address)
        .filter(isNotUndefined);
      this.addressDeletableMap = (addresses.addresses ?? []).reduce(
        (acc, address) => {
          if (!address.address?.token) {
            return acc;
          }
          return {
            ...acc,
            [address.address.token]: !!address.is_deletable,
          };
        },
        {}
      );
    } catch (e) {
      logError(e);
    }
  };

  /**
   * @trackfunction
   */
  static get = singletonGetter(UserStore);

  @observable
  private retailerUserNotificationCount:
    | IRetailerUserNotificationCount
    | undefined = getRetailerUserNotificationCount();

  fetchUser = async () => {
    this.setUser(await getUserAsync());
    this.fetchUserAddresses();
  };

  @action
  setUser = (user: IUser | undefined) => {
    if (!this.user || (user && user.version! >= this.user.version!)) {
      this.user = user;
    }
  };

  /**
   * patch the frontend user. Provided user partial will be used to update this.user
   * @param user partial to be applied on top of existing user
   */
  patchUser = (user: Partial<IUser>) => {
    this.setUser(
      IUser.build({
        ...this.user,
        ...user,
      })
    );
  };

  logOut = async (dest?: string) => {
    await logout();
    CurrencyStore.get().setCurrency(undefined);
    getLocationOrThrow().href = dest ? dest : faireBaseUrl();
  };

  @computed
  get userToken() {
    return this.user?.token ?? "";
  }

  @computed
  get fullName() {
    if (!this.user) {
      return "";
    }
    return `${this.user.first_name} ${this.user.last_name}`.trim();
  }

  @computed
  get firstName() {
    return this.user?.first_name ?? "";
  }

  @computed
  get lastName() {
    return this.user?.last_name ?? "";
  }

  @computed
  get emailAddress(): string | undefined {
    return this.user?.email_address;
  }

  @computed
  get phoneNumber(): string | undefined {
    return this.user?.phone_number;
  }

  @computed
  get addresses(): IAddress[] {
    return this.userAddresses ?? [];
  }

  @computed
  get defaultAddress(): IAddress {
    return this.user?.default_shipping_address ?? IAddress.build();
  }

  @computed
  get isEmployee(): boolean {
    return !!this.user?.is_employee;
  }

  @computed
  get isImpersonating(): boolean {
    return !!this.user?.impersonating;
  }

  // userLocale is used for preferred communication language
  @computed
  private get userLocale(): keyof typeof Language {
    return (
      this.user?.locale_key?.language ??
      getBrowserDefaultLanguage() ??
      DEFAULT_LOCALE_KEY.language
    );
  }

  // browserLevelLanguage is only for browsing purpose. It doesn't save language in database
  // It's is different from preferred communication language.
  @computed
  get browserLevelLanguage(): keyof typeof Language {
    return getLocaleKey()?.language ?? this.userLocale;
  }

  @computed
  get userLocaleKey(): ILocaleKey {
    return (
      this.user?.locale_key ??
      getBrowserDefaultLocaleKey() ??
      DEFAULT_LOCALE_KEY
    );
  }

  @computed
  get browserLevelLocaleKey(): ILocaleKey {
    return getLocaleKey() ?? this.userLocaleKey;
  }

  @computed
  get browserLevelLocaleOverride(): ILocaleKey | undefined {
    const overrideLanguage = getCookies(SHOP_PREVIEW_OVERRIDE_KEY);
    if (
      !overrideLanguage ||
      !localeStringToLocaleKey(overrideLanguage).language
    ) {
      return undefined;
    }
    return localeStringToLocaleKey(overrideLanguage);
  }

  @computed
  get hasFaireEmailAddress(): boolean {
    return !!this.emailAddress?.endsWith("@faire.com");
  }

  @computed
  get isRetailerSubUser(): boolean {
    return !this.userHasSufficientCapability("RETAILER_TRANSFER_OWNERSHIP");
  }

  @computed
  get isOwnerUser(): boolean {
    return this.userHasSufficientCapability("RETAILER_TRANSFER_OWNERSHIP");
  }

  @computed
  get accountType(): IRetailerAccountUserManagement.Role {
    if (!this.isRetailerSubUser) {
      return IRetailerAccountUserManagement.Role.OWNER;
    }
    return this.userHasSufficientCapability(
      "MANAGE_RETAILER_ACCOUNT_USER_PERMISSIONS"
    )
      ? IRetailerAccountUserManagement.Role.ADMIN
      : IRetailerAccountUserManagement.Role.CONTRIBUTOR;
  }

  @computed
  private get userCapabilities(): Array<keyof typeof CapabilityEnum> {
    return this.user?.capabilities ?? [];
  }

  @computed
  get userCanPlaceOrder(): boolean {
    return this.userCapabilities.includes("RETAILER_PLACE_ORDER");
  }

  @computed
  get userProfileImage(): IImage | undefined {
    return this.user?.profile_image;
  }

  @computed
  get isBrandUser(): boolean {
    return this.user?.type === IUser.Type.BRAND_USER;
  }

  @computed
  get isAdminUser(): boolean {
    return this.user?.type === IUser.Type.ADMIN;
  }

  @computed
  get myTeamNotificationCount(): number {
    return (
      this.retailerUserNotificationCount?.un_dismissed_notifications_count_by_group?.find(
        (group) =>
          group.group === RetailerUserNotificationGroup.TEAM_COMMUNICATION
      )?.count ?? 0
    );
  }

  handleRetailerUserNotificationChannelMessage = (message: Message) => {
    if (
      message.type ===
        IWebSocketMessage.Type.RETAILER_USER_NOTIFICATION_COUNT_UPDATED &&
      !!message.retailer_user_notification_count
    ) {
      this.retailerUserNotificationCount =
        message.retailer_user_notification_count;
    }
  };

  userHasSufficientCapability = (
    capability: keyof typeof CapabilityEnum
  ): boolean => {
    return this.userCapabilities.includes(capability);
  };

  addressIsDeletable = (addressToken: string): boolean => {
    if (!addressToken) {
      return false;
    }
    return !!this.addressDeletableMap[addressToken];
  };
}

export const useUserStore = createStoreHook(UserStore);
