import {
  LoggedInRetailer,
  Retailer,
} from "@faire/web-api/indigofair/settings/Serialized";
import { getGlobalProperty } from "@faire/web/common/globals/getGlobalProperty";
import { SerializedValues } from "@faire/web/common/SerializedSetting";

type ISerializedSettings = SerializedValues<Retailer | LoggedInRetailer>;

export const getSettingsValues = (): ISerializedSettings => {
  return getGlobalProperty("settingsValues") || {};
};
